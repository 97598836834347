/* global $ */

class App
{
    constructor()
    {
        this.menuMoving = false;
        this.initSubmenu();
        this.initBannerSlider();
        this.getBootstrap();
        this.disableLogger();
        this.initForms();
        this.initMatrixContent();
        this.initStickyHeader();
        this.initScrollTop();
        this.initLoadMore();
        $('body').css('opacity', 1);
        $(window).scroll(() => {
            this.initStickyHeader();
        });
    }

    initLoadMore() {
        var _this = this;
        $('.podcast-loadmore').on('click', function(){
            var podlimit = $('#podlimit').val();
            var podoffset = $('#podoffset').val();
            var entryid = $('#entryid').val();
            var total = $('#is_more').val();
            // var has_more = $('#has_more').val();
            var btn = 'podcast-loadmore';
            var url = 'getPodcasts';
            _this.loadMorepodcast(url, podlimit, podoffset, entryid, total, btn);
        });
    }

    loadMorepodcast(url, podlimit, podoffset, entryid, total, btn) {
        $.ajax({
            url: '/'+url,
            method: 'get',
            data: {
                offset: podoffset,
                limit: podlimit,
                entryid: entryid
            }
        }).done((data) => {
            let properties = $(data);
            $('.properties-listing > .list-row').append(properties);
            let count = $('.property-item').length;
            console.log(total);
            console.log(count);
            $('#podoffset').val(parseInt(count));
            if (count == total) {
                $('#has_more').val('yes');
                $('.'+btn).css('display', 'none');
            } else {
                $('#has_more').val('no');
                
            }
            
        }).always(() => {
            $('#has_more').val('yes');
        })
    }

    initSubmenu()
    {
        var submenu = function() {
            $("#mobileNav .nav-item.has-children").each(function(i){
                i++;
                $(this).children(".submenu-toggler").attr("data-bs-target","#submenu"+i);
                $(this).children("ul.submenu").attr("id","submenu"+i);
            })
        };
        submenu();
    }

    initBannerSlider()
    {
        if ($('.banner-slider .slick-elem').length > 1) {
            import(/* webpackChunkName: "slick" */ './components/slick').then(() => {
                $('.banner-slider').slick({
                    autoplay: true,
                    arrows: false,
                    dots: false,
                    fade: true,
                    speed: 1500,
                    rows: 0,
                    autoplaySpeed: 6000,
                    cssEase: 'linear'
                });
            })
        }
    }

    getBootstrap()
    {
        if (!this.bootstrap) {
            this.bootstrap = import(/* webpackChunkName: "bootstrap" */ 'bootstrap');
        }
        return this.bootstrap;
    }

    disableLogger()
    {
        if (window.Globals.env == 'production') {
            console.log = function() {};
        }
    }

    initForms()
    {
        if ($('form').length) {
            import(/* webpackChunkName: "form" */ './components/form').then(chunk => {
                $.each($('form.js-validate'), (i, elem) => {
                    new chunk.Form(elem.id);
                });
            });
        }
        if ($('form .datepicker').length) {
            import(/* webpackChunkName: "flatpickr" */ './components/flatpickr').then((chunk) => {
                $.each($(".datepicker"),(i, elem) => {
                    chunk.flatpickr(elem, $(elem).data('options'));
                });
            });
        }
    }

    initMatrixContent()
    {
        if ($('.matrix-block').length) {
            import(/* webpackChunkName: "matrixContent" */ './components/matrixContent');
        }
    }
    initStickyHeader() {
        var headerHeight = $("#header-top").height();
        var scrollTop = $(window).scrollTop();
        if (scrollTop >= headerHeight) {
            $('header').addClass('sticky');
        } else {
            $('header').removeClass('sticky');
        }
        if (scrollTop >= 300) {
            $('.mobile-top-btn').fadeIn();
        } else {
            $('.mobile-top-btn').fadeOut();
        }
    }
    initScrollTop() {
        $(".mobile-top-btn").click(function() {
            $("html, body").animate({ scrollTop: 0 }, "slow");
            return false;
        });
    }
}

export default App;